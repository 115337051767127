import * as React from 'react'

const config = require('../../../client-config')
const QrReader = React.lazy(() => import('react-qr-reader'))
/*  */

const initialMultiStepFormState = {
  step: 1,
  data: {
    codes: [],
    dealer: '',
    dealerKey: -1,
    modelId: -1,
    modelYear: 2023,
  },
  complete: false,
  updatedCodes: false,
  dealers: [],
}
const stepCount = 3

function multiStepFormReducer(state, action) {
  switch (action.type) {
    case 'next':
      if (state.step < stepCount) {
        return { ...state, step: state.step + 1 }
      }
      return state
    case 'previous':
      if (state.step <= stepCount) {
        return { ...state, step: state.step - 1 }
      }
      return state
    case 'scan':
      return {
        ...state,
        data: {
          ...state,
          codes: [...state.data.codes, action.payload.scan],
        },
      }
    case 'selectDealer':
      return {
        ...state,
        data: {
          ...state.data,
          dealer: state.dealers[action.payload],
          dealerKey: action.payload,
        },
      }
    case 'selectModel':
      return {
        ...state,
        data: {
          ...state.data,
          modelId: action.payload,
        },
      }
    case 'selectModelYear':
      let newState = Object.assign({}, state);

      newState.data.data.modelYear = action.payload;

      return newState;
    case 'assign':
      return { ...state, complete: true, step: state.step + 1 }
    case 'complete':
      return { ...state, updatedCodes: action.payload, step: state.step + 1 }

    case 'restart':
      return initialMultiStepFormState
    default:
      throw new Error()
  }
}

function QrLocalizationPage() {
  const [state, dispatch] = React.useReducer(
    multiStepFormReducer,
    initialMultiStepFormState
  )

  function canGoNext() {
    return state.step < stepCount
  }

  function canGoBack() {
    return state.step > 1 && !state.complete
  }

  function canFinish() {
    if (state.step === stepCount) {
      // Check for all required data
      // Validate any data that needs validated
      return true
    }
  }
  function isComplete() {
    if (state.complete) {
      // Check for all required data
      // Validate any data that needs validated
      return true
    }
  }

  getDealers(state)

  return (
    <section>
      <div className="max-w-4xl mx-auto pt-12 px-4 h-screen bg-white shadow-sm">
        <h1>
          {' '}
          <span className="mt-1 block text-4xl tracking-tight font-display font-light sm:text-5xl xl:text-6xl leading-tight">
            QR Code Localizer Machine
          </span>
        </h1>
        <div className="mt-4 p-4 border-2 border-brand-gray-400">
          {(() => {
            switch (state.step) {
              case 1:
                return (
                  <div>
                    <div id="QRScanner">
                      <h2 className="text-lg font-bold">
                        Scan QR Codes to assign a dealer
                      </h2>
                      <QRScanner state={state} dispatch={dispatch} />
                    </div>
                    <div>
                      <ol className="list-decimal list-inside">
                        {state.data.codes.map((code, idx) => (
                          <li key={`code-${idx}`}>
                            <a
                              href={code.url}
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              {code.id}
                            </a>
                          </li>
                        ))}
                      </ol>
                    </div>
                    <div className="mt-4">
                      <button
                        onClick={() => dispatch({ type: 'restart' })}
                        className="text-brand-red-200"
                      >
                        Restart
                      </button>
                    </div>
                  </div>
                )
              case 2:
                return <StepTwo state={state} dispatch={dispatch} />
              case 3:
                return <StepThree state={state} dispatch={dispatch} />
              case 4:
                return (
                  <div>
                    <AssignToDealer state={state} dispatch={dispatch} />
                  </div>
                )
              case 5:
                return (
                  <div>
                    {!state.updatedCodes && <div>PROCESSING</div>}
                    {state.updatedCodes && (
                      <div>
                        <h1>Success!</h1>
                        <p>
                          {state.data.codes.length} QR Codes Update to Dealer:{' '}
                          {state.data.dealer.name}
                        </p>
                      </div>
                    )}
                  </div>
                )
              default:
                return null
            }
          })()}
        </div>
        <div className="mt-8 space-x-4">
          {canGoBack() && (
            <button
              onClick={() => dispatch({ type: 'previous' })}
              className="py-2 px-4 border-brand-red-200 border-2 bg-brand-red-200 hover:bg-white font-bold text-white  hover:text-brand-red-200"
            >
              Go Back
            </button>
          )}
          {canGoNext() && (
            <Next step={state.step} state={state} dispatch={dispatch} />
          )}
          {canFinish() && (
            <button
              onClick={() => dispatch({ type: 'assign' })}
              className="py-2 px-4 border-brand-red-200 border-2 bg-brand-red-200 hover:bg-white font-bold text-white  hover:text-brand-red-200"
            >
              Finish Assigning
            </button>
          )}
          {isComplete() && (
            <button
              onClick={() => dispatch({ type: 'restart' })}
              className="py-2 px-4 border-brand-red-200 border-2 bg-brand-red-200 hover:bg-white font-bold text-white  hover:text-brand-red-200"
            >
              Start New Scan
            </button>
          )}
        </div>
        {!isComplete() && (
          <div className="mt-4 text-center">
            Step: {state.step} of {stepCount}
          </div>
        )}
      </div>
    </section>
  )
}

export default QrLocalizationPage
function Next({ step, state, dispatch }) {
  if (state.step === 1 && state.data.codes.length === 0) {
    return <></>
  } else if (step === 2 && !state.data.dealer) {
    return <></>
  } else {
    return (
      <button
        onClick={() => dispatch({ type: 'next' })}
        className="py-2 px-4 border-brand-red-200 border-2 bg-brand-red-200 hover:bg-white font-bold text-white  hover:text-brand-red-200"
      >
        Next
      </button>
    )
  }
}
/* 
INIT QR Reader
*/
function QRScanner({ state, dispatch }) {
  const isSSR = typeof window === 'undefined'

  return (
    <div id="QRScanner">
      {!isSSR && (
        <React.Suspense fallback={<div>loading...</div>}>
          {state.data.codes.length < 4 && (
            <QrReader
              delay={1000}
              onError={(error) => console.error(error)}
              onScan={(url) => {
                if (url) {
                  getIdFromURL(url, dispatch, state)
                }
              }}
              style={{ width: '100%' }}
            />
          )}
        </React.Suspense>
      )}
    </div>
  )
}
/* 
Select Dealer
*/
function StepTwo({ state, dispatch }) {
  const items = state.dealers
  return (
    <div>
      <h2 className="text-lg font-bold">Select a dealer</h2>
      {state.data.dealer && (
        <>
          <p className="my-2">
            Dealer: {state.data.dealer.name} - {state.data.dealer.city},{' '}
            {state.data.dealer.state}
            <br></br>
            Dealer ID: {state.data.dealer.newmar_id}
          </p>
        </>
      )}
      <form>
        <select
          value={state.data.dealerKey}
          className="w-full border border-brand-red-300"
          onChange={(e) => {
            dispatch({ type: 'selectDealer', payload: e.currentTarget.value })
          }}
        >
          <option value="-1" disabled selected>
            --SELECT DEALER--
          </option>
          {items.map((dealer, idx) => (
            <option key={idx} value={idx}>
              {dealer.name} - {dealer.city}, {dealer.state}
            </option>
          ))}
        </select>
        <div>
          <label htmlFor="modelId">Model ID:</label>
          <input
            className="w-full border border-brand-red-300"
            type="number"
            value={state.data.modelId}
            onChange={(e) =>
              dispatch({
                type: 'selectModel',
                payload: parseInt(e.target.value),
              })
            }
          />
        </div>
        <div>
          <label htmlFor="modelYear">Model Year:</label>
          <select className='w-full border border-brand-red-300' name="modelYear" id="modelYear"
            onChange={(e) => {
              dispatch({
                type: 'selectModelYear',
                payload: e.target.value,
              })
            }}
            value={state.data.modelYear}
          >
            <option value="2023">2023</option>
            <option value="2022">2022</option>
          </select>
          {/* <input
            className="w-full border border-brand-red-300"
            type="number"
            value={state.data.modelId}
            onChange={(e) =>
              dispatch({
                type: 'selectModelYear',
                payload: parseInt(e.target.value),
              })
            }
          /> */}
        </div>
      </form>
    </div>
  )
}
/* 
Confirm Dealer ID and QR Codes
*/
function StepThree({ state, dispatch }) {
  return (
    <div>
      <h2 className="text-lg font-bold">Does this look right?</h2>
      <div className="mt-2 space-y-4">
        <div className="">
          <h3 className="font-bold">Codes</h3>
          <ol className="list-decimal list-inside mt-2">
            {state.data.codes.map((code, idx) => (
              <li key={`code-${idx}`}>{code.id}</li>
            ))}
          </ol>
        </div>
        <div>
          <h3 className="font-bold">Dealer</h3>
          <p className="my-2">
            Dealer: {state.data.dealer.name}
            <br></br>Dealer ID: {state.data.dealer.newmar_id}
          </p>
        </div>
        <div>
          <h3 className="font-bold">Model</h3>
          <p className="my-2">Model #: {state.data.modelId}</p>
          <p className="my-2">Model Year: {state.data.data.modelYear}</p>
        </div>
      </div>
    </div>
  )
}

/* 
Make request to beacon stac API
grab QR code ID from response
dispatch QR code code to state
*/
async function getIdFromURL(url, dispatch, state) {
  if (
    state.data.codes.some((data) => {
      if (data.url === url) {
        return true
      }
      return false
    })
  ) {
    return
  }
  var requestOptions = {
    method: 'GET',
    headers: {
      Authorization: config.beaconStac.token,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  }
  await fetch(
    `https://api.beaconstac.com/api/2.0/qrcodes/?url=${url}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((codeData) => {
      dispatch({
        type: 'scan',
        payload: { scan: { id: codeData.results[0].id, url: url, custom_url: codeData.results[0].campaign.custom_url } },
      })
    })
}

/*
Loop through each QR code ID
Make request to Beacon stac to assign each ID to dealer ID
*/
function AssignToDealer({ state, dispatch }) {
  const codes = state.data.codes
  const dealer = state.data.dealer
  const modelId = state.data.modelId
  const modelYear = state.data.data.modelYear

  const data = { codes, dealer, modelId, modelYear }
  fetch('/api/assignToDealer', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(() => {
    dispatch({ type: 'complete', payload: { updatedCodes: true } })
  })
  return <div>Hang tight while we update the QR codes...</div>
}
/* 
Fetch dealers from dealer portal API
*/
function getDealers(state) {
  const isSSR = typeof window === 'undefined'
  if (!isSSR) {
    const dealers = fetch(`/api/allDealers`)
      .then((response) => response.json())
      .then((data) => {
        return data
      })

    const setDealers = async (state) => {
      const d = await dealers
      /*  */
      state.dealers = d
    }
    setDealers(state)
  }
}
function sendToGoogleSheets(data) {
  fetch('/api/googleSheets', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
